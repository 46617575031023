import TransactionForm from './components/TransactionForm';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  container: {
  },
});

function App() {
  const classes = useStyles();
  return (
    <Container className={classes.container} maxWidth="sm">
      <Box mt={3}>
        <Typography variant="h4" gutterBottom>
          Transaction
        </Typography>
        <TransactionForm />
      </Box>
    </Container>
  );
}

export default App;

const axios = require('axios');
const flatten = require('lodash/flatten');
const API_KEY = process.env.REACT_APP_API_KEY;
const N8N_API_BASE = process.env.REACT_APP_N8N_API_BASE;

const axiosInstance = axios.create({
    baseURL: N8N_API_BASE,
    headers: {
        'x-api-key': API_KEY,
    }
});

export const getListOfCategories = async () => {
    const resposne = await axiosInstance.get('/94d017bf-98b2-41f9-9157-6a00d6747cae');
    const data = resposne.data.data;
    return flatten(data);
}

export const addTransaction = async (transactionData) => {
    const resposne = await axiosInstance.post('/6a3adb23-a9e6-4ae0-819d-e21c4972ce33', {
        ...transactionData
    });
    const data = resposne.data;
    return data;
}


import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as dayjs from 'dayjs';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getListOfCategories, addTransaction } from '../APIs/googleSheet';

const useStyles = makeStyles((theme) => ({
    formContainer: {
    },
    snackbar: {

    }
  }));

function TransactionForm() {
    const { handleSubmit, errors, control, reset } = useForm();

    const [categories, setCategories] = useState([]);
    const classes = useStyles();

    const getCategories = async () => {
        const categories = await getListOfCategories();
        setCategories(categories);
    }

    const [saving, setSaving] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
        setSnackbarOpen(false);
      };

    useEffect(() => {
        getCategories();
    }, []);

    const onSubmit = async data => {
        setSaving(true);
        data.amount = parseInt(data.amount);
        data.date = dayjs(data.date).format('MM/DD/YY');
        let responseData = null;
        try {
            responseData = await addTransaction(data);
        } catch (error) {
            setSnackbarMessage("Error in saving transaction");
            setSnackbarOpen(true);
            setSaving(false);
            return;
        }

        if (responseData.body) {
            setSaving(false);
            setSnackbarMessage("Transaction saved");
            setSnackbarOpen(true);
        } else {
            setSnackbarMessage("Error in saving transaction");
            setSnackbarOpen(true);
            setSaving(false);
        }
    };

    const addAnother = () => {
        reset();
        handleSnackClose();
    }

    return (
        <Box className={classes.formContainer}>
            <Snackbar 
                open={snackbarOpen}
                autoHideDuration={10000}
                onClose={handleSnackClose}
                message={snackbarMessage}
                action={
                    <>
                        <Button color="secondary" size="small" onClick={addAnother}>
                            Add another
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl margin="normal" fullWidth variant="outlined" error={!!errors.amount}>
                    <InputLabel htmlFor="amount">Amount</InputLabel>
                    <Controller
                        name="amount"
                        control={control}
                        defaultValue={""}
                        rules={{
                            required: "Amount is required"
                        }}
                        render={({ onChange, value }) => {
                            return (
                                <OutlinedInput
                                    name="amount"
                                    id="amount"
                                    value={value}
                                    onChange={onChange}
                                    type="number"
                                    label="Amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={60}
                                />
                            );
                        }}
                    />
                    {errors.amount && <FormHelperText>{errors.amount?.message}</FormHelperText>}
                </FormControl>

                <FormControl margin="normal" fullWidth variant="outlined" error={!!errors.description}>
                    <InputLabel htmlFor="description">Description</InputLabel>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={""}
                        rules={{
                            required: "Description is required"
                        }}
                        render={({ onChange, value }) => {
                            return (
                                <OutlinedInput
                                    name="description"
                                    id="description"
                                    value={value}
                                    onChange={onChange}
                                    type="text"
                                    label="Description"
                                    labelWidth={70}
                                />
                            );
                        }}
                    />
                    {errors.description && <FormHelperText>{errors.description?.message}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth variant="outlined" error={!!errors.date}>
                    <Controller
                        name="date"
                        control={control}
                        defaultValue={new Date()}
                        rules={{
                            required: "Date is required"
                        }}
                        render={({ onChange, value }) => {
                            return (
                                <MuiPickersUtilsProvider utils={DayJsUtils}>
                                    <KeyboardDatePicker
                                        name="date"
                                        inputVariant="outlined"
                                        margin="normal"
                                        id="date-picker"
                                        label="Transaction Date"
                                        format="DD/MM/YYYY"
                                        value={value}
                                        onChange={onChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            );
                        }}
                    />
                    {errors.date && <FormHelperText>{errors.date?.message}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth variant="outlined" error={!!errors.category}>
                    <InputLabel id="category-label">Category</InputLabel>

                    <Controller
                        name="category"
                        control={control}
                        defaultValue={''}
                        rules={{
                            required: "Category is required"
                        }}
                        render={({ onChange, value }) => {
                            return (
                                <Select
                                    name="category"
                                    labelId="category-label"
                                    id="category-id"
                                    label="Category"
                                    value={value}
                                    onChange={onChange}                                    
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {categories.map(category => {
                                        return <MenuItem key={category} value={category}>{category}</MenuItem>
                                    })}
                                </Select>
                            );
                        }}
                    />
                    {errors.category && <FormHelperText>{errors.category?.message}</FormHelperText>}
                </FormControl>

                <Button fullWidth style={{marginTop: 8}} type="submit" variant="contained" color="primary" size="large" disabled={saving}>
                    {saving && <CircularProgress size={24} color="secondary" />}
                    {!saving && 'Submit'} 
                </Button>
                {/* <Button color="secondary" size="small" onClick={addAnother}>
                    UNDO
                </Button> */}
            </form>
        </Box>
    );
}

export default TransactionForm;